<template>
	<div class="o-wrap">
		<el-page-header @back="$router.go(-1)" content="采购管理" />
		<el-button class="add-btn" @click="handleAdd">发布</el-button>
		<el-table :data="tableData" border>
			<el-table-column prop="title" label="采购标题"></el-table-column>
			<el-table-column prop="num" label="采购数量"></el-table-column>
			<el-table-column prop="yyhy" label="应用行业"></el-table-column>
			<el-table-column prop="diqu" label="供应商地域"></el-table-column>
			<el-table-column prop="endtime" label="截止时间"></el-table-column>
			<el-table-column prop="count" label="报价次数"></el-table-column>
			<!-- <el-table-column prop="state" label="是否成交">
				<template slot-scope="scope">
					<el-button :type="scope.row.state==1?'success':'danger'"
						:icon="scope.row.state==1?'el-icon-check':'el-icon-close'" circle
						@click="stateBind(scope.$index, scope.row)" ref="iconObj">
					</el-button>
				</template>
			</el-table-column> -->
			<el-table-column prop="state" label="展示状态">
				<template slot-scope="scope">
					<div  v-if="scope.row.state==3">
						{{scope.row.remark}}
					</div>
					<div  v-else-if="scope.row.state==2">
						待审核
					</div>
					<div    v-else-if="scope.row.state==1">
						正常显示
					</div>
					<div    v-else-if="scope.row.state==0">
						正常显示
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="add_time" label="发布时间"></el-table-column>
			<el-table-column fixed="right" label="操作">
				<template slot-scope="scope">
					<el-button type="text" size="small" @click="handleModify(scope.row)">详情</el-button>
					<el-button type="text" size="small" @click="handleDel(scope.row)">删除</el-button>
					<el-button type="text" size="small"  @click="open(scope.row)">查看报价</el-button>
				</template>
			</el-table-column>
		</el-table>
		
		
		<el-dialog
		  title="查看报价"
		  :visible.sync="dialogVisible"
		  width="70%"
		  :before-close="handleClose">
		  <el-table :data="datas" border style="width: 100%;">
		  	<el-table-column prop="add_time" label="报价时间"></el-table-column>
		  	<el-table-column prop="company" label="供应商"></el-table-column>
		  	<el-table-column prop="address" label="所在地区"></el-table-column>
			<el-table-column prop="money" label="金额"></el-table-column>
			<el-table-column prop="zhiwu" label="职务"></el-table-column>
			<el-table-column prop="phone" label="联系电话"></el-table-column>
		  	<el-table-column prop="content" label="本次报价条款"></el-table-column>
			<el-table-column prop="contacts" label="详情"></el-table-column>
		  </el-table>
		  <span slot="footer" class="dialog-footer">
		    <el-button @click="dialogVisible = false">取 消</el-button>
		    <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
		  </span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		formatDate
	} from '@/assets/js/formatDate.js'
	export default {
		data() {
			return {
				dialogVisible:false,
				tableData: [],
				datas:[]
			}
		},
		created() {
			this.$axios.post('/getCaigou').then(data => {
				data.forEach(item => {
					item.diqu = item.province + item.city
					item.add_time = this.formatDate(item.add_time)
				})
				this.tableData = data
			})
		},
		methods: {
			open(row){
				this.dialogVisible = true
				this.$axios.post('/getCaigouBaojiaList',{
						id: row.id,
					}).then(data => {
					this.datas = data
				})
				
			},
			formatDate(time) {
				time = time * 1000
				let date = new Date(time)
				return formatDate(date, 'yyyy-MM-dd')
			},
			stateBind(index, row) {
				if (row.state != 1) {
					this.$axios.post('/caigoustate', {
						id: row.id,
						state: row.state
					}).then(res => {
						if (res == 1) {
							row.state = row.state == 1 ? 0 : 1
						} else {
							this.$message('网络错误，请刷新')
						}
					})
				}
			},
			handleDel(row) {
				this.$confirm('此操作将永久删除该信息，是否继续？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$axios.post('/del_caigou', {
						delss: row.id
					}).then(res => {
						if (res.code == 200) {
							this.$message({
								message: '删除成功',
								type: 'success'
							});
							this.tableData.forEach((val, index) => {
								if (val.id == row.id) {
									this.tableData.splice(index, 1);
								}
							})
						}
					})
				}).catch(() => {})
			},
			handleModify(row) {
				this.$router.push(`/purchase/revise?id=${row.id}`)
			},
			handleAdd() {
				this.$router.push('/purchase/release')
			}
		}
	}
</script>

